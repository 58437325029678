import React, {
    Fragment
  } from 'react'  
import update from 'immutability-helper'
import styled from 'styled-components'
import OfferProductsSetting from './offerProductsSetting'
import {
    LegacyCard,
    TextField,
    FormLayout,
    TextContainer,
    Text,
  } from "@shopify/polaris"
import OfferTypeSelector from "../common/offerTypeSelector/offerTypeSelector";

function ProductTab(props) {
    const { host, shop, token, state, setState, productPickerShow, error, currency, currencySymbol, productEdit, setProductEdit, checkoutOfferType, setCheckoutOfferType, existingOffer } = props;
 
    return (
        <Fragment>
            <LegacyCard sectioned>
              <FormLayout>
              <Text as="h3" variant="headingSm">Name Your offer</Text>
              <TextField
                  label="Internal offer nickname"
                  labelHidden={true}
                  placeholder="Shoelaces offer+"
                  onChange={(val) => {
                    setState(update(state, { name: { $set: val }}))
                    }}
                  value={state.name}
                  />
                {state.id ?
                  <TextContainer spacing="tight">
                    <Text tone="subdued">Offer ID: {state.id}</Text>
                  </TextContainer>
                  :
                  false
                }
              </FormLayout>

              <OfferTypeSelector
                checkoutOfferType={checkoutOfferType}
                setCheckoutOfferType={setCheckoutOfferType}
                state={state}
                setState={setState}
                existingOffer={existingOffer}
              />

            </LegacyCard>
            <OfferProductsSetting host={host} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol} checkoutOfferType={checkoutOfferType}/>
            </Fragment>
    )
}

export default ProductTab;